import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Formula`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Result`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`=FILTER(A2:A,B2:B=TRUE)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Generates a list of elements from A, but only where the element to right (in B) is TRUE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`=FILTER(<MyBigList>,ISNA(MATCH(<MyBigList>,<ShorterList>,0)))`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Generates a list of all the elements in MyBigList that aren't present in ShorterList`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`=iferror(VLOOKUP(A2,OtherSheet!$A$2:$B,2,false),false)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Looks up the value in A2 and returns the 2nd item in its relative row (where A2 == the item in the 1st column)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`=TEXTJOIN("\\n",TRUE,ARRAYFORMULA(REGEXREPLACE(SPLIT(TEXTJOIN(",",TRUE,ARRAYFORMULA(REGEXREPLACE(C3:3," ",""))),","),"^",TEXTJOIN("",TRUE,REGEXREPLACE(B3," ","")," --> "))))`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(WIP) generate mermaid code from a single parent and children, to merged together to form a full flowchart`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      